const mediaConstraintsGenerator = (
  desiredVideoDeviceId,
  desiredAudioDeviceId
) => {
  console.log(
    "Create media constraint with desiredVideoDeviceId " +
      desiredVideoDeviceId +
      " and desiredAudioDeviceId " +
      desiredAudioDeviceId
  );
  return {
    video: {
      // width: { ideal: 1920 },
      // height: { ideal: 1080 }
      deviceId: { ideal: desiredVideoDeviceId },
      frameRate: { ideal: 24 },
      aspectRatio: { ideal: 16 / 9 },
      width: { ideal: 1280 },
      height: { ideal: 720 },
    },
    audio: {
      deviceId: desiredAudioDeviceId,
    },
  };
};

module.exports = { mediaConstraintsGenerator };
