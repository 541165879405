
export class ErrorLogger {
    constructor(logErrorsStrategy) {
        if (logErrorsStrategy === 'sentry') {
            Sentry.init({
                dsn: 'https://865e63d93c0c43139b6f8883d9821353@o372145.ingest.sentry.io/5186354',
                release: '0.5.10',
                environment: 'production',
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing(),
                ],
                tracesSampleRate: 0.5,
                debug: true,
                maxBreadcrumbs: 50
            });
        } else if (logErrorsStrategy === 'http') {
            var self = this;
            window.onerror = function(msg, url, lineNo, columnNo, error) {
                console.debug('logging error', error);
                self.onErrorCallback({
                    msg,
                    url,
                    lineNo,
                    columnNo,
                    error,
                    stack: error ? error.stack : (new Error('')).stack
                });
            };

            console.error = function() {
                var stack = (new Error('')).stack;
                var clean = self.extractLastLineFromStacktrace(stack);

                var msg = 'Error';
                var argArray = [].slice.call(arguments);
                if (typeof argArray[0] === 'string') {
                    msg = argArray[0];
                } else if (typeof argArray[0] === 'object') {
                    if (argArray[0].message) {
                        msg = argArray[0].message;
                    } else if (argArray[0].name) {
                        msg = argArray[0].name;
                    }
                    if (argArray[0].stack) {
                        clean = self.extractLastLineFromStacktrace(argArray[0].stack);
                        stack = argArray[0].stack;
                    }
                }
                self.onErrorCallback({
                    msg: msg,
                    url: clean,
                    lineNo: clean,
                    columnNo: clean,
                    error: argArray,
                    stack: stack
                })
            };
        }
    }

    extractLastLineFromStacktrace(stack) {
        stack = typeof stack !== 'string' ? '' : stack;
        var stackSplitted = stack.split("\n");
        var caller_line = stackSplitted[stackSplitted.length - 1];
        var index = caller_line.indexOf("at ");
        return caller_line.slice(index + 2, caller_line.length);
    }

    onError(callback) {
        this.onErrorCallback = callback;
    }

    onErrorCallback(data) {

    }
}
