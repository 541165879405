const KEY_LOCAL_STORAGE_VIDEO_DEVICE = "selected_video";
const KEY_LOCAL_STORAGE_AUDIO_DEVICE = "selected_audio";
const KEY_LOCAL_STORAGE_MUTE_OPTION = "webrtc_mute_checkbox";

export class LocalStorageAdaptor {
  getMediaConstraintValue() {
    const isIphone = [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform);

    var videoDeviceId = this.getVideoDeviceId();
    var audioDeviceId = this.getAudioDeviceId();

    let mediaConstraints = {
      video: {
        // width: { ideal: 1920 },
        // height: { ideal: 1080 }
        frameRate: { max: 24 },
      },
      audio: {},
    };

    if (videoDeviceId) {
      mediaConstraints.video.deviceId = { ideal: videoDeviceId };
    }

    if (audioDeviceId) {
      mediaConstraints.audio.deviceId = { ideal: audioDeviceId };
    } else {
      mediaConstraints.audio = true;
    }

    return mediaConstraints;
  }

  getMicMutedValue() {
    return this.getOrDefaultValue(KEY_LOCAL_STORAGE_MUTE_OPTION, "0") === "1";
  }

  getVideoDeviceId() {
    return this.getOrDefaultValue(KEY_LOCAL_STORAGE_VIDEO_DEVICE, null);
  }

  getAudioDeviceId() {
    return this.getOrDefaultValue(KEY_LOCAL_STORAGE_AUDIO_DEVICE, null);
  }

  storeMicMuted() {
    this.setValue(KEY_LOCAL_STORAGE_MUTE_OPTION, "1");
  }

  storeMicUnMuted() {
    this.setValue(KEY_LOCAL_STORAGE_MUTE_OPTION, "0");
  }

  setVideoDeviceId(value) {
    if (!value) {
      this.removeItem(KEY_LOCAL_STORAGE_VIDEO_DEVICE);
    } else {
      this.setValue(KEY_LOCAL_STORAGE_VIDEO_DEVICE, value);
    }
  }

  setAudioDeviceId(value) {
    if (!value) {
      this.removeItem(KEY_LOCAL_STORAGE_AUDIO_DEVICE);
    } else {
      this.setValue(KEY_LOCAL_STORAGE_AUDIO_DEVICE, value);
    }
  }

  getOrDefaultValue(key, defaultValue) {
    try {
      return localStorage.getItem(key) || defaultValue;
    } catch (e) {
      console.warn(e);
      return defaultValue;
    }
  }

  setValue(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn(e);
    }
  }

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
    }
  }
}
