let measuredAudioContext = null;
let intervalVolumeChecker = null;
const stopMeasuringInputLevel = () => {
  if (measuredAudioContext) {
    measuredAudioContext.close();
    measuredAudioContext = null;
  }
  if (intervalVolumeChecker) {
    clearInterval(intervalVolumeChecker);
    intervalVolumeChecker = null;
  }
};
const measureInputLevel = (inputDeviceId, callback) => {
  stopMeasuringInputLevel();
  if (!inputDeviceId) return;
  navigator.mediaDevices
    .getUserMedia({
      video: false,
      audio: {
        deviceId: inputDeviceId,
      },
    })
    .then(function (audioStream) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      measuredAudioContext = new AudioContext();
      const audioSource =
        measuredAudioContext.createMediaStreamSource(audioStream);
      const analyser = measuredAudioContext.createAnalyser();
      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;
      audioSource.connect(analyser);
      const volumes = new Uint8Array(analyser.frequencyBinCount);
      intervalVolumeChecker = setInterval(() => {
        analyser.getByteFrequencyData(volumes);
        let volumeSum = 0;
        for (const volume of volumes) volumeSum += volume;
        const averageVolume = volumeSum / volumes.length;
        // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
        const vol = (averageVolume * 100) / 127;

        callback(vol);
      }, 100);
    })
    .catch(function (err) {
      /* handle the error */
      console.error(err);
    });
};

export { measureInputLevel, stopMeasuringInputLevel };
