const evaluateConnectionAndReturnScoring = (
  currentStatsObject,
  lastStatsObject,
  currentOutgoingBitrate,
  packetLost,
  rtt
) => {
  //Comparison with last stats
  let evaluatedScorings = [5];

  if (currentStatsObject.currentFPS > 0) {
    switch (true) {
      case currentStatsObject.currentFPS < 10:
        evaluatedScorings.push(1);
        break;
      case currentStatsObject.currentFPS < 15:
        evaluatedScorings.push(2);
        break;
      case currentStatsObject.currentFPS < 20:
        evaluatedScorings.push(3);
        break;
      case currentStatsObject.currentFPS < 23:
        evaluatedScorings.push(4);
        break;
      default:
    }
  }

  if (currentOutgoingBitrate > 0) {
    switch (true) {
      case currentOutgoingBitrate < 200:
        evaluatedScorings.push(1);
        break;
      case currentOutgoingBitrate < 400:
        evaluatedScorings.push(2);
        break;
      case currentOutgoingBitrate < 600:
        evaluatedScorings.push(3);
        break;
      case currentOutgoingBitrate < 750:
        evaluatedScorings.push(4);
        break;
      default:
        break;
    }
  }
  if (lastStatsObject) {
    // PacketLost
    if (packetLost) {
      let packetLostDifference =
        packetLost -
        (lastStatsObject.packetLost ? lastStatsObject.packetLost : 0);
      switch (true) {
        case packetLostDifference < 1:
          break;
        case packetLostDifference < 5:
          evaluatedScorings.push(4);
          break;
        case packetLostDifference < 10:
          evaluatedScorings.push(3);
        case packetLostDifference < 15:
          evaluatedScorings.push(2);
          break;
        default:
          evaluatedScorings.push(1);
      }
    }

    if (lastStatsObject && lastStatsObject.qualityLimitationDurations) {
      if (currentStatsObject.qualityLimitationDurations) {
        const currentQualityLimitationDurations = JSON.parse(
          currentStatsObject.qualityLimitationDurations
        );
        const previousQualityLimitationDurations = JSON.parse(
          lastStatsObject.qualityLimitationDurations
        );
        let previousLimitationsTotals =
          previousQualityLimitationDurations.other +
          previousQualityLimitationDurations.cpu +
          previousQualityLimitationDurations.bandwidth;
        let currentLimitationsTotals =
          currentQualityLimitationDurations.other +
          currentQualityLimitationDurations.cpu +
          currentQualityLimitationDurations.bandwidth;

        if (previousLimitationsTotals && currentLimitationsTotals) {
          let difference = currentLimitationsTotals - previousLimitationsTotals;
          switch (true) {
            case difference < 10:
              break;
            case difference < 300:
              evaluatedScorings.push(4);
              break;
            case difference < 4000:
              evaluatedScorings.push(3);
              break;
            default:
              evaluatedScorings.push(2);
          }
        }
      }
    }
  }

  if (rtt) {
    switch (true) {
      case rtt < 0.1:
        break;

      case rtt < 0.2:
        evaluatedScorings.push(4);
        break;

      case rtt < 0.4:
        evaluatedScorings.push(3);
        break;

      case rtt < 1:
        evaluatedScorings.push(2);
        break;
      default:
        evaluatedScorings.push(1);
        break;
    }
  }

  return Math.min(...evaluatedScorings);
};

export default evaluateConnectionAndReturnScoring;
